.MN-bg_color {
    background-color: var(--MN-bg_color) ;
}

.MN-title_color {
    color: var(--MN-title_color);
}

.MN-title_size {
    font-size: var(--MN-title_size) ;
}

.MN-title_font_family {
    font-family: var(--MN-title_font_family) ;
}

.MN-title_variant {
    font-style: var(--MN-title_variant-style)!important;
    font-weight: var(--MN-title_variant-weight)!important;
}

/*BUTTONS*/

.MN-Button1-bg_color {
    background-color: var(--MN-Button1-bg_color);
  }

  .MN-Button1-icon_color {
    color: var(--MN-Button1-icon_color);
  }

  .MN-Button1-border_color {
    border-color: var(--MN-Button1-border_color);
  }

  /*HEADER*/
.MN-Header-title_color {
    color: var(--MN-Header-title_color);
  }

  .MN-Header-title_size {
    font-size: var(--MN-Header-title_size);
  }

  .MN-Header-title_font_family {
    font-family: var(--MN-Header-title_font_family) !important;
  }

  .MN-Header-title_variant {
    font-style: var(--MN-Header-title_variant-style) !important;
    font-weight: var(--MN-Header-title_variant-weight) !important;
}


  .MN-Header-text_color {
    color: var(--MN-Header-text_color);
  }

  .MN-Header-text_size {
    font-size: var(--MN-Header-text_size);
  }

  .MN-Header-text_font_family {
    font-family: var(--MN-Header-text_font_family) !important;
  }

  .MN-Header-text_variant {
    font-style: var(--MN-Header-text_variant-style)!important;
    font-weight: var(--MN-Header-text_variant-weight)!important;
  }

  .MN-Header-bar_bg_color {
    background-color: var(--MN-Header-bar_bg_color);
  }

  .MN-Header-bar_border_color {
    border-color: var(--MN-Header-bar_border_color);
  }

  .MN-Header-bar_border_width {
    border-width: var(--MN-Header-bar_border_width);
  }

  .MN-Header-bar_border_rounded {
    border-radius: var(--MN-Header-bar_border_rounded);
  }

  .MN-Header-bar_border_position {
    border-style: var(--MN-Header-bar_border_position);
  }

   /*Formfield*/

   .MN-FormField-text_color {
    color: var(--MN-FormField-text_color);
  }

  .MN-FormField-text_size {
    font-size: var(--MN-FormField-text_size);
  }

  .MN-FormField-text_font_family {
    font-family: var(--MN-FormField-text_font_family) !important;
  }

  .MN-FormField-text_variant {
    font-style: var(--MN-FormField-text_variant-style);
    font-weight: var(--MN-FormField-text_variant-weight);
  }

  .MN-FormField-bg_color {
    background-color: var(--MN-FormField-bg_color);
  }

    .MN-FormField-border_color {
      border-color: var(--MN-FormField-border_color);
    }


    .MN-FormField-border_rounded {
      border-radius: var(--MN-FormField-border_rounded);
    }

    .MN-FormField-border_position {
      border-style: var(--MN-FormField-border_position);
    }

    .MN-FormField-border_width {
      border-width: var(--MN-FormField-border_width);
    }

    .MN-FormField-placeholder_color::placeholder {
      color: var(--MN-FormField-placeholder_color) !important;
    }

    .MN-FormField-placeholder_font_family::placeholder {
      font-family: var(--MN-FormField-placeholder_font_family) !important;
    }

    .MN-FormField-placeholder_variant::placeholder {
      font-style: var(--MN-FormField-placeholder_variant-style) !important;
      font-weight: var(--MN-FormField-placeholder_variant-weight) !important;
    }







