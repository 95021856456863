.CK-bg_color {
    background-color: var(--CK-bg_color) ;
}

.CK-title_color {
    color: var(--CK-title_color);
}

.CK-title_size {
    font-size: var(--CK-title_size) ;
}

.CK-title_font_family {
    font-family: var(--CK-title_font_family) ;
}

.CK-title_variant {
    font-style: var(--CK-title_variant-style)!important;
    font-weight: var(--CK-title_variant-weight)!important;
}

/*BUTTONS*/

.CK-Button1-bg_color {
    background-color: var(--CK-Button1-bg_color);
  }

  .CK-Button1-icon_color {
    color: var(--CK-Button1-icon_color);
  }

  .CK-Button1-border_color {
    border-color: var(--CK-Button1-border_color);
  }

  /*HEADER*/
.CK-Header-title_color {
    color: var(--CK-Header-title_color);
  }

  .CK-Header-title_size {
    font-size: var(--CK-Header-title_size);
  }

  .CK-Header-title_font_family {
    font-family: var(--CK-Header-title_font_family) !important;
  }

  .CK-Header-title_variant {
    font-style: var(--CK-Header-title_variant-style) !important;
    font-weight: var(--CK-Header-title_variant-weight) !important;
}


  .CK-Header-text_color {
    color: var(--CK-Header-text_color);
  }

  .CK-Header-text_size {
    font-size: var(--CK-Header-text_size);
  }

  .CK-Header-text_font_family {
    font-family: var(--CK-Header-text_font_family) !important;
  }

  .CK-Header-text_variant {
    font-style: var(--CK-Header-text_variant-style)!important;
    font-weight: var(--CK-Header-text_variant-weight)!important;
  }

  .CK-Header-bar_bg_color {
    background-color: var(--CK-Header-bar_bg_color);
  }

  .CK-Header-bar_border_color {
    border-color: var(--CK-Header-bar_border_color);
  }

  .CK-Header-bar_border_width {
    border-width: var(--CK-Header-bar_border_width);
  }

  .CK-Header-bar_border_rounded {
    border-radius: var(--CK-Header-bar_border_rounded);
  }

  .CK-Header-bar_border_position {
    border-style: var(--CK-Header-bar_border_position);
  }

   /*TABLE*/

   .CK-Table-border_color {
    border-color: var(--CK-Table-border_color);
  }

  .CK-Table-border_width {
    border-width: var(--CK-Table-border_width);
  }

  .CK-Table-border_rounded {
    border-radius: var(--CK-Table-border_rounded);
  }

  .CK-Table-border_position {
    border-style: var(--CK-Table-border_position);
  }

  .CK-Table-header_bg_color {
    background-color: var(--CK-Table-header_bg_color);
  }

  .CK-Table-header_border_color {
    border-color: var(--CK-Table-header_border_color);
  }

  .CK-Table-header_border_width {
    border-width: var(--CK-Table-header_border_width);
  }

  .CK-Table-header_border_rounded {
    border-radius: var(--CK-Table-header_border_rounded);
  }

  .CK-Table-header_border_position {
    border-style: var(--CK-Table-header_border_position);
  }


.CK-Table-header_title_color {
    color: var(--CK-Table-header_title_color);
  }

  .CK-Table-header_title_size {
    font-size: var(--CK-Table-header_title_size);
  }

  .CK-Table-header_title_font_family {
    font-family: var(--CK-Table-header_title_font_family) !important;
  }

  .CK-Table-header_title_variant {
    font-style: var(--CK-Table-header_title_variant-style) !important;
    font-weight: var(--CK-Table-header_title_variant-weight) !important;
}

.CK-Table-body_rowheader_bg_color {
    background-color: var(--CK-Table-body_rowheader_bg_color);
  }

  .CK-Table-body_rowheader_title_color {
    color: var(--CK-Table-body_rowheader_title_color);
  }

  .CK-Table-body_rowheader_title_size {
    font-size: var(--CK-Table-body_rowheader_title_size);
  }

  .CK-Table-body_rowheader_font_family {
    font-family: var(--CK-Table-body_rowheader_font_family) !important;
  }

  .CK-Table-body_rowheader_title_variant {
    font-style: var(--CK-Table-body_rowheader_title_variant-style) !important;
    font-weight: var(--CK-Table-body_rowheader_title_variant-weight) !important;
}

.CK-Table-body_rowheader_hover_bg_color:hover {
    background-color: var(--CK-Table-body_rowheader_hover_bg_color);
  }

  .CK-Table-body_rowheader_border_color {
    border-color: var(--CK-Table-body_rowheader_border_color);
  }

  .CK-Table-body_rowheader_border_width {
    border-width: var(--CK-Table-body_rowheader_border_width);
  }

  .CK-Table-body_rowheader_border_rounded {
    border-radius: var(--CK-Table-body_rowheader_border_rounded);
  }

  .CK-Table-body_rowheader_border_position {
    border-style: var(--CK-Table-body_rowheader_border_position);
  }

  .CK-Table-body_rowheader_icon_color {
    color: var(--CK-Table-body_rowheader_icon_color);
  }

  .CK-Table-body_row_bg_color1 {
    background-color: var(--CK-Table-body_row_bg_color1);
  }

  .CK-Table-body_row_bg_color2 {
    background-color: var(--CK-Table-body_row_bg_color2);
  }

  .CK-Table-body_row_text_color {
    color: var(--CK-Table-body_row_text_color);
  }

  .CK-Table-body_row_text_size {
    font-size: var(--CK-Table-body_row_text_size);
  }

  .CK-Table-body_row_text_font_family {
    font-family: var(--CK-Table-body_row_text_font_family) !important;
  }

  .CK-Table-body_row_text_variant {
    font-style: var(--CK-Table-body_row_text_variant-style) !important;
    font-weight: var(--CK-Table-body_row_text_variant-weight) !important;
  }

  .CK-Table-body_row_border_color {
    border-color: var(--CK-Table-body_row_border_color);
  }

  .CK-Table-body_row_border_width {
    border-width: var(--CK-Table-body_row_border_width);
  }

  .CK-Table-body_row_border_rounded {
    border-radius: var(--CK-Table-body_row_border_rounded);
  }

  .CK-Table-body_row_border_position {
    border-style: var(--CK-Table-body_row_border_position);
  }











