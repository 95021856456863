.RS-bg_color {
    background-color: var(--RS-bg_color) ;
}

.RS-title_color {
    color: var(--RS-title_color);
}

.RS-title_size {
    font-size: var(--RS-title_size) ;
}

.RS-title_font_family {
    font-family: var(--RS-title_font_family) ;
}

.RS-title_variant {
    font-style: var(--RS-title_variant-style)!important;
    font-weight: var(--RS-title_variant-weight)!important;
}

.RS-Card-bg_color {
    background-color: var(--RS-Card-bg_color) ;
}

.RS-Card-title_color {
    color: var(--RS-Card-title_color);
}

.RS-Card-title_size {
    font-size: var(--RS-Card-title_size) ;
}

.RS-Card-title_font_family {
    font-family: var(--RS-Card-title_font_family) ;
}

.RS-Card-title_variant {
    font-style: var(--RS-Card-title_variant-style)!important;
    font-weight: var(--RS-Card-title_variant-weight)!important;
}

.RS-Card-text_color {
    color: var(--RS-Card-text_color);
}

.RS-Card-text_size {
    font-size: var(--RS-Card-text_size) ;
}

.RS-Card-text_font_family {
    font-family: var(--RS-Card-text_font_family) ;
}

.RS-Card-text_variant {
    font-style: var(--RS-Card-text_variant-style)!important;
    font-weight: var(--RS-Card-text_variant-weight)!important;
}

.RS-Card-border_color {
    border-color: var(--RS-Card-border_color);
}

.RS-Card-border_rounded {
    border-radius: var(--RS-Card-border_rounded);
}

.RS-Card-border_position {
    border-style: var(--RS-Card-border_position);
}

.RS-Card-border_width {
    border-width: var(--RS-Card-border_width);
}

.RS-Card-icon_color {
    color: var(--RS-Card-icon_color);
}










