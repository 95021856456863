.EM-bg_color {
    background-color: var(--EM-bg_color) ;
}

.EM-Card-bg_color {
    background-color: var(--EM-Card-bg_color) ;
}

.EM-Card-title_color {
    color: var(--EM-Card-title_color);
}

.EM-Card-title_size {
    font-size: var(--EM-Card-title_size) ;
}

.EM-Card-title_font_family {
    font-family: var(--EM-Card-title_font_family) ;
}

.EM-Card-title_variant {
    font-style: var(--EM-Card-title_variant-style)!important;
    font-weight: var(--EM-Card-title_variant-weight)!important;
}

.EM-Card-text_color {
    color: var(--EM-Card-text_color);
}

.EM-Card-text_size {
    font-size: var(--EM-Card-text_size) ;
}

.EM-Card-text_font_family {
    font-family: var(--EM-Card-text_font_family) ;
}

.EM-Card-text_variant {
    font-style: var(--EM-Card-text_variant-style)!important;
    font-weight: var(--EM-Card-text_variant-weight)!important;
}

.EM-Card-border_color {
    border-color: var(--EM-Card-border_color);
}

.EM-Card-border_rounded {
    border-radius: var(--EM-Card-border_rounded);
}

.EM-Card-border_position {
    border-style: var(--EM-Card-border_position);
}

.EM-Card-border_width {
    border-width: var(--EM-Card-border_width);
}

.EM-Card-promotion_bg_color {
    background-color: var(--EM-Card-promotion_color) ;
}

.EM-Card-promotion_text_color {
    color: var(--EM-Card-promotion_color) ;
}

.EM-Card-allergen_text_color {
    color: var(--EM-Card-allergen_text_color);
}

.EM-Card-allergen_text_size {
    font-size: var(--EM-Card-allergen_text_size) ;
}

.EM-Card-allergen_text_font_family {
    font-family: var(--EM-Card-allergen_text_font_family) ;
}

.EM-Card-allergen_text_variant {
    font-style: var(--EM-Card-allergen_text_variant-style)!important;
    font-weight: var(--EM-Card-allergen_text_variant-weight)!important;
}

.EM-Card-allergen_text_bg_color {
    background-color: var(--EM-Card-allergen_text_bg_color) ;
}

.EM-EMenuCard-with_image_title_color {
    color: var(--EM-EMenuCard-with_image_title_color);
}

.EM-EMenuCard-with_image_title_size {
    font-size: var(--EM-EMenuCard-with_image_title_size) ;
}

.EM-EMenuCard-with_image_title_font_family {
    font-family: var(--EM-EMenuCard-with_image_title_font_family) ;
}

.EM-EMenuCard-with_image_title_variant {
    font-style: var(--EM-EMenuCard-with_image_title_variant-style)!important;
    font-weight: var(--EM-EMenuCard-with_image_title_variant-weight)!important;
}

.EM-EMenuCard-with_image_text_color {
    color: var(--EM-EMenuCard-with_image_text_color);
}

.EM-EMenuCard-with_image_text_size {
    font-size: var(--EM-EMenuCard-with_image_text_size) ;
}

.EM-EMenuCard-with_image_text_font_family {
    font-family: var(--EM-EMenuCard-with_image_text_font_family) ;
}

.EM-EMenuCard-with_image_text_variant {
    font-style: var(--EM-EMenuCard-with_image_text_variant-style)!important;
    font-weight: var(--EM-EMenuCard-with_image_text_variant-weight)!important;
}

.EM-EMenuCard-with_image_border_color {
    border-color: var(--EM-EMenuCard-with_image_border_color);
}

.EM-EMenuCard-with_image_border_rounded {
    border-radius: var(--EM-EMenuCard-with_image_border_rounded);
}

.EM-EMenuCard-with_image_border_position {
    border-style: var(--EM-EMenuCard-with_image_border_position);
}

.EM-EMenuCard-with_image_border_width {
    border-width: var(--EM-EMenuCard-with_image_border_width);
}

.EM-EMenuCard-no_image_title_color {
    color: var(--EM-EMenuCard-no_image_title_color);
}

.EM-EMenuCard-no_image_title_size {
    font-size: var(--EM-EMenuCard-no_image_title_size) ;
}

.EM-EMenuCard-no_image_title_font_family {
    font-family: var(--EM-EMenuCard-no_image_title_font_family) ;
}

.EM-EMenuCard-no_image_title_variant {
    font-style: var(--EM-EMenuCard-no_image_title_variant-style)!important;
    font-weight: var(--EM-EMenuCard-no_image_title_variant-weight)!important;
}

.EM-EMenuCard-no_image_text_color {
    color: var(--EM-EMenuCard-no_image_text_color);
}

.EM-EMenuCard-no_image_text_size {
    font-size: var(--EM-EMenuCard-no_image_text_size) ;
}

.EM-EMenuCard-no_image_text_font_family {
    font-family: var(--EM-EMenuCard-no_image_text_font_family) ;
}

.EM-EMenuCard-no_image_text_variant {
    font-style: var(--EM-EMenuCard-no_image_text_variant-style)!important;
    font-weight: var(--EM-EMenuCard-no_image_text_variant-weight)!important;
}

.EM-EMenuCard-no_image_border_color {
    border-color: var(--EM-EMenuCard-no_image_border_color);
}

.EM-EMenuCard-no_image_border_rounded {
    border-radius: var(--EM-EMenuCard-no_image_border_rounded);
}

.EM-EMenuCard-no_image_border_position {
    border-style: var(--EM-EMenuCard-no_image_border_position);
}

.EM-EMenuCard-no_image_border_width {
    border-width: var(--EM-EMenuCard-no_image_border_width);
}

.EM-EMenuCard-no_image_bg_color {
    background-color: var(--EM-EMenuCard-no_image_bg_color);
}

.EM-Searchbar-bg_color {
    background-color: var(--EM-Searchbar-bg_color);
}

.EM-Searchbar-border_color {
    border-color: var(--EM-Searchbar-border_color);
}

.EM-Searchbar-border_rounded {
    border-radius: var(--EM-Searchbar-border_rounded);
}

.EM-Searchbar-border_position {
    border-style: var(--EM-Searchbar-border_position);
}

.EM-Searchbar-border_width {
    border-width: var(--EM-Searchbar-border_width);
}

.EM-Searchbar-icon_color {
    color: var(--EM-Searchbar-icon_color);
}




