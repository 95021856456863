.QR-bg_color {
    background-color: var(--QR-bg_color) ;
}

  /*HEADER*/
.QR-Header-title_color {
    color: var(--QR-Header-title_color);
  }

  .QR-Header-title_size {
    font-size: var(--QR-Header-title_size);
  }

  .QR-Header-title_font_family {
    font-family: var(--QR-Header-title_font_family) !important;
  }

  .QR-Header-title_variant {
    font-style: var(--QR-Header-title_variant-style) !important;
    font-weight: var(--QR-Header-title_variant-weight) !important;
}


  .QR-Header-text_color {
    color: var(--QR-Header-text_color);
  }

  .QR-Header-text_size {
    font-size: var(--QR-Header-text_size);
  }

  .QR-Header-text_font_family {
    font-family: var(--QR-Header-text_font_family) !important;
  }

  .QR-Header-text_variant {
    font-style: var(--QR-Header-text_variant-style)!important;
    font-weight: var(--QR-Header-text_variant-weight)!important;
  }

  .QR-Header-bar_bg_color {
    background-color: var(--QR-Header-bar_bg_color);
  }

  .QR-Header-bar_border_color {
    border-color: var(--QR-Header-bar_border_color);
  }

  .QR-Header-bar_border_width {
    border-width: var(--QR-Header-bar_border_width);
  }

  .QR-Header-bar_border_rounded {
    border-radius: var(--QR-Header-bar_border_rounded);
  }

  .QR-Header-bar_border_position {
    border-style: var(--QR-Header-bar_border_position);
  }

