:root {
    --primary-color: #e8b73d; /* Set your desired primary color here */
    --text-color: #07294c;
    --light-text-color: #07294ce0;
    --dark-text-color: #ffffffd9;
    --white-bg-color: white;
    --dark-bg-color: #1f1f1f; /*  white for default theme or for dark #1f1f1f*/
    --font-color: white; /*  white for default theme or for dark #1f1f1f*/
    --pms-color: #5c89ff;
    --pms-dark-color: #4096ff;
    --pms-light-color: #5c89ff1a;
    --pos-color: #0f9f67;
    --pos-dark-color: #0d8d5b;
    --pos-light-color: #0f9f671a;
    --wss-color: #b18efb;
    --wss-light-color: #9da8fc49;
    --ems-color: #58bba8;
    --ems-light-color: #0f9f671a;
    --oms-color: #00b4d8;
    --oms-light-color: #caf0f8;
    --ag-color: #66d3be;
    --dark-border-color: #424242;
    --light-border-color: #cdd4db;
    --light-warning-color: #cf1322;
    --dark-warning-color: #e84749;
    --light-success-color: #389e0d;
    --dark-success-color: #6abe39;
    --pms-form-bg-color: #f9fafc;
    --grid-row-selected: #cedcf9;
    --pms-weekend-color: #f1f5ff;
    --hostgrid-hover-color: #5c8aff3a;
    --host-r24-bg-color: #e6e6e6;
    --host-r24-sec-bg-color: #f5f5f5;
    --host-r24-card-color: #ffffff;
    --host-r24-disabled-color: #07294c0a;
    --host-r24-selection-color: #e9e9e9;
    --host-r24-hover-color: #e9e9e970;
    --host-r24-border-color: #d9d9d9;
    --host-r24-border-color-2: #e8e8e8;
    --host-r24-border-color-3: #cccccc;
    --host-r24-font-color-lighter: #07294ca6;
    --host-r24-font-color: #07294ce0;
    --host-r24-font-color-darker: #07294c;
    --host-r24-error-color: #cf1322;
    --host-r24-warning-color: #cfcf05;
    --host-r24-success-color: #389e0d;
    --host-r24-grid-zebra-color: #e2e2e240;
    --host-r24-grid-selection-color: #cedcf9;
    --host-r24-grid-locked-cell: #f2f4f676;
    --host-r24-grid-hover-color: #5c8aff3a;
    --host-r24-grid-cell-error-color: #e84749;
    --host-r24-grid-cell-error-color-2: #e847491a;
    --host-r24-grid-cell-success-color: #90ee90;
    --host-r24-grid-cell-success-color-2: #d4f1ed;
    --host-r24-font-white-color: #ffffff;
    --host-r24-body-font-size: 12px;
    --host-r24-box-shadow: #07294c1a;
    --host-r24-info-color: rgb(101, 183, 243);
    --host-r24-primary-color: #1677FF;
    --host-r24-primary-color-hover: #4096FF;
    --host-r24-tooltip-background: #2c4967;
  }

  @import url("../node_modules/devextreme/dist/css/dx.light.css");

.app .ant-layout {
  background: var(--host-r24-bg-color) !important;
}

.host-r24-layout .ant-layout-header {
  height: 55px;
  background: var(--host-r24-bg-color) !important;
}

.ant-picker-dropdown .ant-picker-panel .ant-picker-footer {
  border-top: none !important;
}

.ant-picker-dropdown .ant-picker-panel .ant-picker-footer {
  border-bottom: none !important;
}

.ant-picker-footer .ant-picker-footer-extra {
  border-right: 1px solid var(--host-r24-border-color-2) !important;
}

.host-r24-layout .dx-datagrid-rowsview .dx-row {
  border-bottom: none;
  border-top: none;
}

.ant-input-disabled {
  color: var(--host-r24-font-color) !important;
}

.ant-input-number-disabled {
  color: var(--host-r24-font-color) !important;
}

.host-r24-layout .dx-datagrid-headers {
  border-bottom: none;
}

.host-r24-layout .input,
.host-r24-layout .label,
.host-r24-layout label,
.hhs-modal .input,
.hhs-modal .label,
.hhs-modal label {
  font: normal 12px "Poppins", sans-serif !important;
  font-family: "Poppins", sans-serif !important;
}

.host-r24-layout input::file-selector-button,
.hhs-modal input::file-selector-button {
  font-family: "Poppins", sans-serif !important;
  background-color: var(--host-r24-antd-input-background) !important;
  color: var(--host-r24-font-color) !important;
}

.host-r24-layout .ant-radio-button-wrapper,
.hhs-modal .ant-radio-button-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.new-host-form-wrapper {
  background-color: var(--host-r24-sec-bg-color) !important;
  border-radius: 5px;
}

/* cr - color of tooltip in dark mode */
.host-r24-layout .g2-tooltip {
  background-color: var(--host-r24-sec-bg-color) !important;
  color: var(--host-r24-font-color) !important;
}

.hhs-modal .g2-tooltip {
  background-color: var(--host-r24-sec-bg-color) !important;
  color: var(--host-r24-font-color) !important;
}

.host-form-wrapper
  .form-item-active-filter-field
  .ant-select
  .ant-select-selector {
  border: 1px solid var(--pms-color) !important;
}

.host-form-wrapper .form-item-active-filter-field input {
  border: 1px solid var(--pms-color) !important;
}

.host-form-wrapper .form-item-active-filter-field .ant-select input {
  border: none !important;
}

.host-grid thead {
  background-color: var(--host-r24-sec-bg-color) !important;
  border: 1px solid var(--host-r24-border-color-2) !important;
  font-family: "Poppins", sans-serif !important;
}

.host-grid th {
  border: 1px solid var(--host-r24-border-color-2) !important;
  font-size: 14px !important;
  color: var(--host-r24-font-color) !important;
  padding: 8px !important;

}

.host-grid td {
  border-bottom: 1px solid var(--host-r24-border-color-2)  !important;
  font-size: 12px !important;
  color: var(--host-r24-font-color) !important;
  padding: 7px !important;
}

.host-grid tbody tr:nth-child(even) {
  background-color: var(--host-r24-sec-bg-color) !important;
}

.host-grid tbody tr:nth-child(even):hover {
  background-color: var(--host-r24-grid-hover-color) !important;
}

.host-grid tbody tr:hover {
  background-color: var(--host-r24-grid-hover-color) !important;
}

.host-grid-button {
  padding: 5px 15px;
  background-color: var(--white-bg-color) !important;
  border: 1px solid var(--host-r24-border-color-2);
  color: var(--host-r24-font-color) !important;
  border-radius: 6px;
  font-size: 12px;
  height: 32px;
}

.host-grid-button-disabled {
  padding: 5px 15px;
  background-color: var(--host-r24-hover-color);
  border: 1px solid var(--host-r24-border-color-2);
  color: var(--host-r24-font-color);
  border-radius: 6px;
  font-size: 12px;
}

.host-grid-button:hover {
  border: 1px solid var(--host-r24-info-color) !important;
  color: var(--host-r24-info-color) !important;
}

.host-button-primary {
  padding: 5px 15px;
  background-color: var(--host-r24-primary-color) !important;
  color: var(--host-r24-font-white-color) !important;
  border-radius: 6px;
  font-size: 12px;
  align-items: center;
  width: 52px;
  height: 32px;
}

.host-button-primary:hover {
  background-color: var(--host-r24-primary-color-hover) !important;
}

.host-search-input {
  padding: 2px 11px;
  background-color: var(--white-bg-color);
  border: 1px solid var(--host-r24-border-color);
  color: var(--text-color) !important;
  border-radius: 6px;
  font-size: 12px;
  align-items: center;
  height: 32px;
}

.host-search-input::placeholder {
  font-size: 12px;
  align-items: center;
}

.host-select-input {
  padding: 2px 11px;
  background-color: var(--white-bg-color) !important;
  border: 1px solid var(--host-r24-border-color) !important;
  color: var(--text-color) !important;
  border-radius: 6px;
  font-size: 12px;
  align-items: center;
  appearance: none;
  position: relative;
}

.host-title {
  font-size: 16px;
  font-family: "Poppins" !important;
  color: var(--text-color);
  font-weight: 600;
}

.host-title-modal {
  font-size: 18px;
  font-family: "Poppins" !important;
  color: var(--text-color);
  font-weight: 600;
}

.host-frame-title {
  font-size: 14px;
  font-family: "Poppins" !important;
  color: var(--text-color);
  font-weight: 600;
}

.host-subtitle {
  font-size: 14px;
  font-family: "Poppins" !important;
  color: var(--text-color) !important;
  font-weight: 400;
}

.host-menu {
  font-size: 16px;
  font-family: "Poppins" !important;
  color: white !important;
  font-weight: 400;
}

.host-submenu {
  font-size: 14px;
  font-family: "Poppins" !important;
  color: white !important;
  font-weight: 400;
}

.host-text {
  font-size: 12px;
  font-family: "Poppins" !important;
  color: var(--text-color) ;
}

.host-text-modal {
  font-size: 14px;
  font-family: "Poppins" !important;
  color: var(--text-color) ;
}

.host-text-error {
  font-size: 12px;
  font-family: "Poppins" !important;
  color: var(--host-r24-error-color) !important;
}


@keyframes tooltipFade {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Para a animação de saída */
.tooltip-fade-out {
  animation: tooltipFadeOut 0.3s ease-in-out forwards;
}

@keyframes tooltipFadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(5px);
  }
}

/* Base scrollbar width */
::-webkit-scrollbar {
  width: 5px;
  height: 3px;
  transition: width 1s ease;
}



/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  margin: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555555da;
}

::-webkit-scrollbar-corner {
  background-color: transparent !important;
}

.ant-select-multiple .ant-select-selection-item {
  cursor: move;
  user-select: none;
}

.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
}




